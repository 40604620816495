import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NftItem from "./NftItem";
import { getLang } from "../utils/getLang";

import { Pagination, Modal, Carousel, Tooltip, Tabs, Typography } from "antd";
import dayjs from "dayjs";
import { getSignInInfo } from "../requests";
import { useAccount } from "wagmi";
import against from "../utils/against";

import "dayjs/locale/zh-cn";
import "./NftList.less";

import adoptLogo from "@/assets/img/home/adopt_logo.png";
import nftTitle from "@/assets/img/home/nft_title.png";
import currBnb from "@/assets/img/home/curr_bnb.png";
import NFTMALL from "@/assets/img/home/NFT_MALL.png";
import NFTMALLCN from "@/assets/img/cnimg/NFTMALLCN.png";
import More from "@/assets/img/home/More.png";
import test from "@/assets/img/home/test.png";
import closa from "@/assets/img/home/closa.png";
import useInterval from "../hooks/useInterval";

dayjs.locale("en");

interface NftListProps {
  nftList: any;
  doBuy: (param: any) => void;
  total: number;
  current: number;
  onChange: (param: any) => void;
  isProfile: boolean;
  onTabMallChange?: (type: string) => void;
}

const NftList = ({
  nftList,
  doBuy,
  total,
  current,
  onChange,
  isProfile,
  onTabMallChange,
}: NftListProps) => {
  const { t, i18n } = useTranslation();
  const lang = getLang();
  const { address, isConnected } = useAccount();
  const { Paragraph } = Typography;
  /*    function handleClick() {
        navigate('/shop');
    }*/

  const nftItemMove = (e: any) => {
    console.log(e.clientX);
  };

  // const list = [
  //   {
  //     image: test,
  //     name: "dasdaadas",
  //     nftId: 13516,
  //     price: 10,
  //   },
  //   {
  //     image: test,
  //     name: "dasdaadas",
  //     nftId: 13516,
  //     price: 10,
  //   },
  // ];

  // 轮播图
  const carouseRef = useRef<any>(null);
  const [isShowCarouse, setIsShowCarouse] = useState(false);

  const checkCarouse = () => {
    setIsShowCarouse(false);
  };
  const showCarousel = (index: any) => {
    setIsShowCarouse(true);
    document.body.classList.add("modal_open");

    // 防止打开弹窗切换图片时，弹窗闪动一下
    setTimeout(() => {
      carouseRef.current?.goTo(index);
    }, 0);
  };
  const afterOpenChange = (open: boolean) => {
    if (!open) {
      document.body.classList.remove("modal_open");
    }
  };

  const nftListRef = useRef<any>(null);
  const onChangePage = (page: any) => {
    nftListRef.current.scrollIntoView({ behavior: "instant" });
    onChange(page);
  };
  // 多少TMIR
  const [tmir, setTmir] = useState(0);
  // 多少USDT
  const [tmirUsdt, setTmirUsdt] = useState(0);

  // Tabs
  const items = [
    {
      key: "1",
      label: t("NFTs I own"),
    },
  ];
  // NFT Mall Tabs
  const itemsMall = [
    {
      key: "",
      label: t("All"),
    },
    {
      key: "MH",
      label: t("Blind Box"),
    },
    {
      key: "FO",
      label: t("Buddha Amulet"),
    },
  ];
  const onTabsChange = (key: any) => {
    console.log(key);
  };

  const getInfo = async () => {
    if (!address) return;
    const obj = {
      address: address?.toLowerCase(),
    };
    const res = await getSignInInfo(obj);
    if (res.data) {
      setTmir(res.data.tmir);
      setTmirUsdt(res.data.usdt);
    }
  };
  // useEffect(() => {
  //   if (!isProfile) return;
  //   getInfo();
  // }, []);
  useInterval(getInfo);

  return (
    <div className="nft_list" ref={nftListRef}>
      <div
        className={
          isProfile ? "nft_list_top nft_list_top_profile" : "nft_list_top"
        }
      >
        <div className="nft_list_title">
          <img src={adoptLogo} alt="" className="nft_list_title_logo" />

          {isProfile ? (
            <div className="nft_list_title_f">{t("Login")}</div>
          ) : lang === "en" ? (
            <img src={NFTMALL} alt="" className="nft_list_title_font" />
          ) : (
            <img src={NFTMALLCN} alt="" className="nft_list_title_font" />
          )}

          {!isProfile && (
            <Link to="/shop" className="more">
              {lang === "en" ? (
                <img src={More} alt="" />
              ) : (
                <div>{t("More")}</div>
              )}
            </Link>
          )}
        </div>
        {isProfile && (
          <div className="show_pc">
            <div className="show_pc_t">{t("Login")}</div>
            <div className="show_pc_address">
              <Paragraph
                copyable={{
                  text: address,
                }}
              >
                {against(address)}
              </Paragraph>
            </div>
          </div>
        )}
        {isProfile && <div className="tmir">{tmir} TMIR</div>}
        {isProfile && <div className="tmir_usdt">=${tmirUsdt} USDT</div>}
      </div>

      {isProfile && (
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onTabsChange}
          className="may_tabs"
        />
      )}
      {!isProfile && (
        <Tabs
          defaultActiveKey=""
          items={itemsMall}
          onChange={onTabMallChange}
          className="nft_mall_tabs"
        />
      )}
      <div className="nft_list_con">
        {nftList.map(
          (
            item: {
              image: string | undefined | any;
              name:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | Iterable<React.ReactNode>
                | null
                | undefined;
              nftId:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | Iterable<React.ReactNode>
                | null
                | undefined;
              price:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | Iterable<React.ReactNode>
                | null
                | undefined;
            },
            i: React.Key | null | undefined
          ) => (
            <NftItem
              key={i}
              item={item}
              isProfile={isProfile}
              doBuy={doBuy}
              onClick={isProfile ? () => showCarousel(i) : () => null}
            />
          )
        )}
      </div>

      <div className="pageDiv">
        <Pagination
          total={total}
          defaultPageSize={6}
          defaultCurrent={1}
          current={current}
          onChange={onChangePage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        open={isShowCarouse}
        onCancel={checkCarouse}
        afterOpenChange={afterOpenChange}
        centered
        footer={null}
        closable={false}
        className="carouse_modal"
        rootClassName="carouse_modal_root"
      >
        <div className="carousel_closa">
          <img src={closa} onClick={checkCarouse} alt="" />
        </div>
        <Carousel
          ref={carouseRef}
          autoplay={false}
          dots={false}
          arrows={true}
          className="carouse_modal_carousel"
        >
          {nftList.map(
            (
              item: {
                image: string | undefined | any;
                name:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | Iterable<React.ReactNode>
                  | null
                  | undefined;
                nftId:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | Iterable<React.ReactNode>
                  | null
                  | undefined;
                price:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | Iterable<React.ReactNode>
                  | null
                  | undefined;
                descs: any;
              },
              i: React.Key | null | undefined
            ) => (
              <div className="carouse_modal_con" key={i}>
                <img src={item.image} alt="" className="carouse_modal_img" />
                <div className="carouse_modal_mask"></div>
                <div className="carouse_modal_info">
                  <div className="name">
                    <div className="name_con">
                      {item.name} #{item.nftId}
                    </div>
                  </div>
                  <div className="price">
                    <div className="price_con">
                      <div className="price_c">
                        <img src={currBnb} alt="" />
                        <span className="price_currency">USDT</span>
                        <span className="price_num">{item.price}</span>
                      </div>
                    </div>
                  </div>
                  <div className="explain">
                    <div className="explain_t">{t("Explain")}</div>
                    <Tooltip title={item.descs} trigger={["hover", "click"]}>
                      <div className="explain_c">{item.descs}</div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )
          )}
        </Carousel>
      </Modal>
    </div>
  );
};

export default NftList;
