import React from "react";
import { useTranslation } from "react-i18next";

import "./footer.less";

import tw from "@/assets/img/footer/tw.png";
import discord from "@/assets/img/footer/discord.png";
import me from "@/assets/img/footer/me.png";
import tel from "@/assets/img/footer/tel.png";
import gitbook from "@/assets/img/footer/gitbook.png";
import logo from "@/assets/img/header/logo.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="one_nft">{t("ONE APE ONE NFT")}</div>
      <div className="links">
        <a href="https://discord.com/invite/miraweb3" target="_blank">
          <img src={discord} alt="" className="discord" />
        </a>
        <a href="https://x.com/MiRA__WEB3" target="_blank">
          <img src={tw} alt="" className="twitter" />
        </a>
        <a href="https://t.me/mira_web3" target="_blank">
          <img src={tel} alt="" className="twitter" />
        </a>
        <a href="https://medium.com/@miraproject2025" target="_blank">
          <img src={me} alt="" className="twitter" />
        </a>
        <a href="https://mira-3.gitbook.io/mira" target="_blank">
          <img src={gitbook} alt="" className="twitter" />
        </a>
      </div>
      <img src={logo} alt="" className="nft_logo" />
    </div>
  );
};

export default Footer;
