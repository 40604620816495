import { useState, useEffect } from 'react';

function useIsMobile() {
    // 设置一个状态来保存是否是移动端
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            // 当窗口调整大小时更新isMobile状态
            setIsMobile(window.innerWidth < 768);
        };

        // 添加事件监听器
        window.addEventListener('resize', handleResize);

        // 清除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
}

export default useIsMobile;
