import {useCallback} from 'react';
import {usdtTokenContract} from "./usdtTokenContract";
import {createPublicClient, createWalletClient, custom, http} from "viem";
import {Chain} from "viem/_types/types/chain";


const useTokenApprove = (chain?: Chain | undefined) => {
    const publicClient = createPublicClient({chain: chain, transport: http(),});


    const handleDomainCommit = useCallback(async (tokenAddress:string, spider:string) => {
            try{
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...usdtTokenContract,
                    address: tokenAddress as `0x${string}`,
                    functionName: 'approve',
                    args: [spider as `0x${string}`, BigInt("50000000000000000000000")],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
            }
        },
        [ publicClient, chain],
    );
    return { doApprove: handleDomainCommit };
};

export default useTokenApprove;



