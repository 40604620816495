import {useCallback} from 'react';
import {createPublicClient, createWalletClient, custom, http} from "viem";
import {sellToolContract} from "./sellToolContract";
import {SELL_MANAGER} from "../utils/constants";
import {Chain} from "viem/_types/types/chain";
import {toBigString} from "../utils/formatBalance";
import {Web3} from "web3";

const useWithdrawToken = ( chain?: Chain | undefined) => {
    const publicClient = createPublicClient({chain: chain, transport: http(),});


    const handleDomainCommit = useCallback(async (nftId:string, amount: string, times: number) => {
            try{
                const total = parseFloat(amount) * times;
                let amountValue = Web3.utils.toWei(total, "ether").toString();
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...sellToolContract,
                    address: SELL_MANAGER,
                    functionName: 'buyNft',
                    args: [BigInt(nftId), BigInt(amountValue), BigInt(times)],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
            }
        },
        [ publicClient, chain, ],
    );
    return { doWithdraw : handleDomainCommit };
};

export default useWithdrawToken;



