export const formatAddress = (str) => {
    if (!str) return ''
    if (str.length <= 9) {
        return str; // 如果字符串长度小于或等于9，则返回原始字符串
    }
    return str.slice(0, 6) + '...' + str.slice(-4);
}

export const formatLastTime = (time) => {
    var hours = Math.floor(time / 3600);
    var minutes = Math.floor(Math.floor(time % 3600) / 60);
    var seconds = Math.floor(time % 60);
    var h = hours.toString().length === 1 ? `0${hours}` : hours;
    var m = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    var s = seconds.toString().length === 1 ? `0${seconds}` : seconds;
    return `${h}:${m}`;
}