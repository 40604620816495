import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const toBigString = (balance: string, decimals = 18) => {
  const displayBalance = new BigNumber(balance).multipliedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toString();
}


export const toBigNumber = (balance: string, decimals = 18) => {
  const displayBalance = new BigNumber(balance).multipliedBy(new BigNumber(10).pow(decimals))
  return displayBalance;
}


export const getDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}


export const getMinBalance = (balance: BigNumber, decimals = 18, length=9) => {
  const x = balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(length).toString();
   if(x.indexOf(".")>=0){
     return x.substring(0, x.length - 1);
   }
  return x;
}


export const getFastBalance = (value: any, decimals = 18) => {
  const balance = new BigNumber(value.toString());
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '')
  }
}

