import { API } from "./api";

// 绑定邀请关系
// export const getCreateInvite = async (body) => {
//   return await API.post("api/index/create_invite", body);
// };

// 签到
export const getSignIn = async (query) => {
  return await API.get("/common/common/sign", query);
};

// 获取用户信息
export const getUserInfo = async (query) => {
  return await API.get("/common/myNftList", query);
};

// 获取签到信息
export const getSignInInfo = async (query) => {
  return await API.get("/common/getInfo", query);
};

// 获取文章列表
export const getArticleList = async (query) => {
  return await API.get("/common/getArticleList", query);
};

// 获取NFT列表
export const getNftList = async (query) => {
  return await API.get("/common/sellList", query);
};
