import {getDefaultConfig} from "@rainbow-me/rainbowkit";
import {bsc} from "wagmi/chains";
import {
    bitgetWallet,
    coinbaseWallet, imTokenWallet, injectedWallet,
    okxWallet, oneKeyWallet, rabbyWallet,
    rainbowWallet, safeWallet, trustWallet, uniswapWallet,
    walletConnectWallet, zealWallet
} from "@rainbow-me/rainbowkit/wallets";

export const WalletConfig = getDefaultConfig({
    appName: 'Papas',
    projectId: 'YOUR_PROJECT_ID',
    wallets:[
        {
            groupName: 'Recommended',
            wallets: [rainbowWallet, walletConnectWallet,
                okxWallet,imTokenWallet,coinbaseWallet, bitgetWallet,
                injectedWallet,oneKeyWallet,rabbyWallet,safeWallet,
                trustWallet,uniswapWallet,zealWallet
            ],
        }
    ],
    chains: [
        bsc,
    ],
});