import React, { useCallback, useState, useEffect } from "react";
import { SELL_MANAGER, USDT_TOKEN } from "../../utils/constants";
import { useTranslation } from "react-i18next";

import "./shop.less";

import homeTopBg from "@/assets/img/home/home_top_bg.png";
import homeContent from "@/assets/img/home/home_content.png";
import colorBar from "@/assets/img/home/color_bar.png";
import close from "@/assets/img/home/close.png";
import useInterval from "../../hooks/useInterval";
import { createPublicClient, http } from "viem";
import { arbitrum, bsc } from "wagmi/chains";
import { useAccount } from "wagmi";
import NftList from "../../Components/NftList";
import Footer from "../../Components/Footer";
import { usdtTokenContract } from "../../hooks/usdtTokenContract";
import { getBalanceNumber, toBigString } from "../../utils/formatBalance";
import BigNumber from "bignumber.js";
import useTokenApprove from "../../hooks/useTokenApprove";
import useWithdrawToken from "../../hooks/useWithdrawToken";
import { getLang } from "../../utils/getLang";
import { getNftList } from "../../requests";
import { message } from "antd";

const Shop = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const lang = getLang();

  // NFT列表
  const [nftList, setNftList] = useState([]);

  const { address } = useAccount();
  const publicClient = createPublicClient({ chain: bsc, transport: http() });
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const onChange = (page: any) => {
    setCurrent(page);
    getNftListData(nftType, page);
  };

  const [usdtAllowance, setUsdtAllowance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  // NFt列表分类名FO:佛牌，MH：盲盒，空为所有
  const [nftType, setNftType] = useState("");
  const getNftListData = async (
    type: string = nftType,
    pageNum: number = current
  ) => {
    const obj = {
      pageSize: 6,
      pageNum,
      lang: lang === "cn" ? "zh" : "en",
      type,
    };
    const resList = await getNftList(obj);
    if (resList["code"] === 200) {
      const listRow = resList["data"];
      setNftList(listRow);
      setTotal(resList["total"]);
    }
  };
  const fetchSellNftList = useCallback(async () => {
    try {
      const baseAllowance = await publicClient.readContract({
        ...usdtTokenContract,
        address: USDT_TOKEN,
        functionName: "allowance",
        args: [address as `0x${string}`, SELL_MANAGER as `0x${string}`],
      });
      const baseAllowanceValue = getBalanceNumber(
        new BigNumber(baseAllowance.toString())
      );
      console.info("baseAllowance=>" + baseAllowance);
      setUsdtAllowance(baseAllowanceValue);

      const usdtBalance = await publicClient.readContract({
        ...usdtTokenContract,
        address: USDT_TOKEN,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      });
      const usdtBalanceValue = getBalanceNumber(
        new BigNumber(usdtBalance.toString())
      );
      console.info("usdtBalanceValue=>" + usdtBalanceValue);
      setUsdtBalance(usdtBalanceValue);
    } catch (e) {
      console.error(e);
    }
  }, [
    setNftList,
    setTotal,
    setUsdtAllowance,
    setUsdtBalance,
    current,
    address,
    SELL_MANAGER,
    USDT_TOKEN,
  ]);
  useInterval(fetchSellNftList);

  const { doApprove } = useTokenApprove(bsc);
  const { doWithdraw } = useWithdrawToken(bsc);
  // NFT购买
  const Buy = async (buyNftItem: any) => {
    console.log("Buy-NFT", buyNftItem);
    if (!address) {
      return message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("error_linkwallet"),
      });
    }
    if (usdtBalance < parseFloat(buyNftItem.price.toString())) {
      return message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("error_usdt"),
      });
    }
    if (usdtAllowance < parseFloat(buyNftItem.price.toString())) {
      message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("error_approve"),
      });
      await doApprove(USDT_TOKEN, SELL_MANAGER);
      return;
    }

    await doWithdraw(buyNftItem.nftId, buyNftItem.price.toString(), 1);
  };

  const onTabMallChange = async (type: string) => {
    setCurrent(1);
    setNftType(type);
    getNftListData(type, 1);
  };

  useEffect(() => {
    getNftListData();
  }, []);
  return (
    <div className="shop">
      <div className="home_bg">
        <img src={homeTopBg} alt="" className="home_top_bg" />
      </div>

      <div className="home_con">
        <div className="home_con_bg">
          <img src={homeContent} alt="" className="home_content" />
        </div>
        <img src={colorBar} alt="" className="color_bar" />
        <div className="home_con_content">
          <div className="home_con_content_main">
            <NftList
              nftList={nftList}
              doBuy={Buy}
              onChange={onChange}
              current={current}
              total={total}
              isProfile={false}
              onTabMallChange={onTabMallChange}
            />
          </div>
        </div>
        <img src={colorBar} alt="" className="color_bar" />
      </div>
    </div>
  );
};

export default Shop;
