import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, Divider } from "antd";

import currBnb from "@/assets/img/home/curr_bnb.png";
import "./nftitem.less";

interface NftItemProps {
  item: any;
  isProfile: boolean;
  doBuy: (param: any) => void;
  onClick: (param: any) => void;
}

const NftItem = ({ item, isProfile, doBuy, onClick }: NftItemProps) => {
  const { t, i18n } = useTranslation();
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [showNextElement, setShowNextElement] = useState(false);
  const handleMouseDown = (event: any) => {
    setDragging(true);
    setStartX(event.clientX);
  };

  const handleMouseMove = (event: any) => {
    if (dragging) {
      setEndX(event.clientX);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  // 移动端
  const handleTouchStart = (event: any) => {
    setDragging(true);
    const touch = event.touches[0];
    setStartX(touch.clientX);
  };

  const handleTouchMove = (event: any) => {
    if (dragging) {
      const touch = event.touches[0];
      setEndX(touch.clientX);
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };
  // useEffect(() => {
  //   const distanceMoved = startX - endX;

  //   // 根据具体需求决定何时显示下一个元素
  //   if (distanceMoved >= 100) {
  //     setShowNextElement(true);
  //   } else if (distanceMoved <= -100) {
  //     setShowNextElement(false);
  //   }
  // }, [endX, startX]);
  // 是否显示buy按钮
  // const [showBuyBtn, setShowBuyBtn] = useState(false);
  const buyBtnRef = useRef<any>(null);
  // useEffect(() => {
  //   window.addEventListener("click", (e) => {
  //     const isInside = buyBtnRef.current?.contains(e.target);
  //     if (isInside) {
  //       setShowBuyBtn(true);
  //     } else {
  //       setShowBuyBtn(false);
  //     }
  //   });
  // }, []);
  return (
    <div
      className="nft_list_item"
      // onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      // onTouchStart={handleTouchStart}
      // onTouchMove={handleTouchMove}
      // onTouchEnd={handleTouchEnd}
      onClick={onClick}
    >
      <div className="nft_list_item_com">
        <div className="item_bg" ref={buyBtnRef}>
          <img src={item.image} alt="" className="item_logo" />
          {isProfile ? null : (
            // <div
            //   className={
            //     showNextElement
            //       ? "nft_list_item_moda nft_list_item_moda_show"
            //       : "nft_list_item_moda"
            //   }
            // >
            <div className="nft_list_item_moda">
              <div className="desc_title">{t("NFT Description")}</div>
              <div className="desc_info">
                <div className="desc_text">
                  {t("Name")}
                  {item.name}
                </div>
                <div className="desc_text">
                  {t("Price")}
                  {item.price}
                </div>
                <div className="desc_text">
                  {t("Total Quantity")}
                  {item.stock + item.sold}
                </div>
                <div className="desc_text">
                  {t("Remaining Quantity")}
                  {item.stock}
                </div>
              </div>
              <div className="desc_con">
                <div className="desc_con_tit">{t("NFT Description")}：</div>
                <Tooltip title={item.descs} trigger={["hover", "click"]}>
                  <div className="desc_con_text">{item.descs}</div>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div className="item_con">
          {isProfile && <div className="item_sign">{item.name}</div>}
          {!isProfile && (
            <Tooltip title={item.name} trigger={["hover", "click"]}>
              <div className="item_sign">{item.name}</div>
            </Tooltip>
          )}
          {isProfile && (
            <Tooltip
              title={item.descs + "#" + item.nftId}
              trigger={["hover", "click"]}
            >
              <div className="item_name item_name_pc">
                #{item.nftId} {item.descs}
              </div>
            </Tooltip>
          )}
          {isProfile && (
            <div className="item_name item_name_mobile">
              #{item.nftId} {item.descs}
            </div>
          )}
          <Divider className={isProfile ? "line" : "line line_nop"}>
            {isProfile && item.amount}
          </Divider>
          <div className="item_func">
            <div className="item_curr_con">
              <div className="item_curr">
                <img src={currBnb} alt="" />
              </div>
              <span className="item_price">USDT {item.price}</span>
            </div>
            {isProfile ? (
              <div />
            ) : (
              // <div
              //   className={showBuyBtn ? "buy_btn show_buy_btn" : "buy_btn"}
              //   onClick={() => doBuy(item)}
              // >
              //   {t("BUY NOW")}
              // </div>
              <div className={"buy_btn"} onClick={() => doBuy(item)}>
                {t("BUY NOW")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftItem;
