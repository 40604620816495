import React, { useCallback, useState } from "react";
import { SELL_MANAGER, USDT_TOKEN } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../requests";

import "./profile.less";

import homeTopBg from "@/assets/img/home/home_top_bg.png";
import homeContent from "@/assets/img/home/home_content.png";
import colorBar from "@/assets/img/home/color_bar.png";
import useInterval from "../../hooks/useInterval";
import { createPublicClient, http } from "viem";
import { arbitrum, bsc } from "wagmi/chains";
import { useAccount } from "wagmi";
import NftList from "../../Components/NftList";

const Home = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  // NFT列表
  const [nftList, setNftList] = useState([]);

  const { address } = useAccount();
  // const address = "0x5a4d4b03ae40cf74bbda36f402509d65037fe222";
  const publicClient = createPublicClient({ chain: bsc, transport: http() });
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const onChange = async (page: any) => {
    setCurrent(page);
    await fetchSellNftList();
  };

  const [usdtAllowance, setUsdtAllowance] = useState(0);
  const fetchSellNftList = useCallback(async () => {
    try {
      if (!address) return;
      const obj = {
        pageSize: 6,
        pageNum: current,
        address: address.toLowerCase(),
      };
      const resList = await getUserInfo(obj);
      if (resList["code"] === 200) {
        console.log("resList", resList);

        const listRow = resList["data"];
        setNftList(listRow);
        setTotal(resList["total"]);
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    setNftList,
    setTotal,
    setUsdtAllowance,
    current,
    address,
    SELL_MANAGER,
    USDT_TOKEN,
    address,
  ]);
  useInterval(fetchSellNftList);

  // NFT购买
  const Buy = async (buyNftItem: any) => {
    console.log("购买NFT", buyNftItem);
  };

  return (
    <div className="profile">
      <div className="home_bg">
        <img src={homeTopBg} alt="" className="home_top_bg" />
      </div>
      <div className="home_con">
        <div className="home_con_bg">
          <img src={homeContent} alt="" className="home_content" />
        </div>
        <img src={colorBar} alt="" className="color_bar" />
        <div className="home_con_content">
          <div className="home_con_content_main">
            <NftList
              nftList={nftList}
              doBuy={Buy}
              onChange={onChange}
              current={current}
              total={total}
              isProfile={true}
            />
          </div>
        </div>
        <img src={colorBar} alt="" className="color_bar" />
      </div>
    </div>
  );
};

export default Home;
